const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",

  //ANONYMOUS

  // ADMIN



}
export default ROUTER
